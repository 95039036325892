import { FunctionComponentElement } from "react";
import cn from "classnames";

import PrivacyPolicy from "../components/static/PrivacyPolicy";

function PrivacyPolicyPage(): FunctionComponentElement<unknown> {
  return (
    <>
      <div className="container-background">
        <div className="container">
          <div className="fake-header-button" />
          <div
            className={cn(
              "frame",
              "d-flex flex-column",
              "border border-1 rounded-3",
              "fullScreenPortrait"
            )}
          >
            <PrivacyPolicy />
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
