import { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

interface OutroProps extends WithTranslation {
  hasClose: boolean;
}

function Outro({
  hasClose,
  t,
}: OutroProps): FunctionComponentElement<OutroProps> {
  return (
    <div className="mt-5 mb-3 d-flex flex-column flex-grow-1 justify-content-between">
      <div>
        <div className="d-flex-column mb-4">
          <h6 className="mb-1">
            <b>{t("section1-heading")}</b>
          </h6>
          <p>{t("section1-para")}</p>
        </div>
        <div className="d-flex-column mb-4">
          <h6 className="mb-1">
            <b>{t("section2-heading")}</b>
          </h6>
          <p>{t("section2-para")}</p>
        </div>
        {!hasClose && (
          <div className="d-flex-column mb-4">
            <p>Herzlichen Dank, Sie können dieses Fenster schließen.</p>
          </div>
        )}
      </div>
      {hasClose && (
        <div className="d-flex justify-content-end">
          <Button key="close" onClick={() => window.close()}>
            {t("btn-close", { ns: "common" })}
          </Button>
        </div>
      )}
    </div>
  );
}
export default withTranslation(["outro", "common"])(Outro);
