import { FunctionComponentElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import AssessmentService from "../../../api/AssessmentService";
import IAssessment from "../../../interfaces/IAssessment";
import IQuestionnaire from "../../../interfaces/IQuestionnaire";
import Questionnaire from "../Questionnaire/Questionnaire";
import QuestionnaireComplete from "../QuestionnaireComplete/QuestionnaireComplete";
import styles from "./Assessment.module.scss";
import Loader from "../../ui/Loader/Loader";
import ShowError from "../../ui/ShowError";
import IReadiness from "../../../interfaces/IReadiness";

interface AssessmentProps {
  questionnaire: IQuestionnaire;
  setReadiness: (readiness: IReadiness) => void;
}

function Assessment({
  questionnaire,
  setReadiness,
}: AssessmentProps): FunctionComponentElement<AssessmentProps> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] =
    useState<boolean>(false);

  function handleLastQuestionNextClick(assessment: IAssessment[]): void {
    setLoading(true);
    new AssessmentService()
      .getReadiness(assessment)
      .then((response) => {
        // let x = Object.assign(new Readiness(), response.data);
        setReadiness(response.data);
        setIsQuestionnaireCompleted(true);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleResultClick(): void {
    navigate("/result");
  }

  if (error) {
    return <ShowError error={error} />;
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <div className={cn("my-3", styles.container)}>
      {isQuestionnaireCompleted ? (
        <QuestionnaireComplete onResultClick={handleResultClick} />
      ) : (
        <Questionnaire
          questionnaire={questionnaire}
          onLastQuestionNextClick={handleLastQuestionNextClick}
        />
      )}
    </div>
  );
}

export default Assessment;
