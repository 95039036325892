import { FunctionComponentElement } from "react";
import cn from "classnames";

import Outro from "../components/static/Outro";

interface OutroPageProps {
  isPdfRedirected?: boolean;
}

function OutroPage({
  isPdfRedirected = false,
}: OutroPageProps): FunctionComponentElement<OutroPageProps> {
  return (
    <>
      <div className="container-background">
        <div className="container">
          <div className="fake-header-button" />
          <div
            className={cn(
              "frame",
              "d-flex flex-column",
              "border border-1 rounded-3",
              "fullScreenPortrait"
            )}
          >
            <Outro hasClose={!isPdfRedirected} />
          </div>
        </div>
      </div>
    </>
  );
}

export default OutroPage;
