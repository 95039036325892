import { FunctionComponentElement, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Form, InputGroup } from "react-bootstrap";

import styles from "./ContactForm.module.scss";
import IContactForm from "../../../interfaces/IContactForm";

interface ContactFormProps extends WithTranslation {
  isAgreementChecked: boolean;
  onNextClick: (formDetails: IContactForm) => void;
}

function ContactForm({
  isAgreementChecked,
  onNextClick,
  t,
}: ContactFormProps): FunctionComponentElement<ContactFormProps> {
  const [validated, setValidated] = useState(false);

  const [form, setForm] = useState<IContactForm>({
    lastName: "",
    firstName: "",
    company: "",
    email: "",
    message: "",
    assessmentId: "",
  });

  function handleInputChange(event: React.ChangeEvent<any>) {
    const updatedForm = { ...form, [event.target.name]: event.target.value };
    setForm(updatedForm);
  }

  function isFormComplete(): boolean {
    return (
      !!form.firstName && !!form.lastName && !!form.email && isAgreementChecked
    );
  }

  const handleSubmit = (event: any) => {
    const formData = event.currentTarget;
    if (formData.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onNextClick(form);
    }
    setValidated(true);
  };

  return (
    <div className="my-3 d-flex flex-column flex-grow-1 justify-content-between">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div>
          <div className="d-flex align-items-baseline">
            <h3>{t("heading")}</h3>
          </div>
          <hr />
          <div className="questionnaire-content">
            <h5>{t("contact-us")}</h5>
            <Form.Group
              className="mt-4 d-flex justify-content-between form-input"
              controlId="lastName"
            >
              <Form.Label className={styles.label}>
                {t("last-name")}:
              </Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                required
                value={form.lastName}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>

            <Form.Group
              className="mt-2 d-flex justify-content-between form-input"
              controlId="firstName"
            >
              <Form.Label className={styles.label}>
                {t("first-name")}:
              </Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                required
                value={form.firstName}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>

            <Form.Group
              className="mt-2 d-flex justify-content-between form-input"
              controlId="company"
            >
              <Form.Label className={styles.label}>{t("company")}:</Form.Label>
              <Form.Control
                name="company"
                type="text"
                value={form.company}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>

            <Form.Group
              className="mt-2 d-flex justify-content-between form-input"
              controlId="email"
            >
              <Form.Label className={styles.label}>{t("email")}:</Form.Label>
              <InputGroup>
                <Form.Control
                  name="email"
                  type="email"
                  required
                  pattern="^[\-a-zA-Z0-9+_.]+@([\-a-zA-Z0-9])+\.[a-zA-Z0-9]+$"
                  value={form.email}
                  onChange={(e) => handleInputChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie eine gültige E-Mail Adresse ein.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mt-4 mb-2" controlId="message">
              <Form.Control
                name="message"
                as="textarea"
                placeholder={t("message") || ""}
                rows={5}
                value={form.message}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            key="contact-form-next"
            type="submit"
            disabled={!isFormComplete()}
          >
            {t("btn-next", { ns: "common" })}
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default withTranslation(["contact-form", "common"])(ContactForm);
