import cn from "classnames";
import { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import Carousel from "../Carousel/Carousel";

import styles from "./QuestionnaireComplete.module.scss";

interface QuestionnaireCompleteProps extends WithTranslation {
  onResultClick(): void;
}

function QuestionnaireComplete({
  onResultClick,
  t,
}: QuestionnaireCompleteProps): FunctionComponentElement<QuestionnaireCompleteProps> {
  return (
    <Carousel isQuestionnaireCompleted onResultClick={onResultClick}>
      <div className={cn("questionnaire-content", styles.content)}>
        <h4 className={styles.description}>{t("completed-txt")}</h4>
      </div>
    </Carousel>
  );
}

export default withTranslation("questionnaire")(QuestionnaireComplete);
