export enum LevelOfGovernance {
  STRATEGIC = "STRATEGIC",
  PROACTIVE = "PROACTIVE",
  CONFORM = "CONFORM",
  ACTIVATING = "ACTIVATING",
}

export enum LevelOfGovernanceScore {
  STRATEGIC = 4,
  PROACTIVE = 3,
  CONFORM = 2,
  ACTIVATING = 1,
}
