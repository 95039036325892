import { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation, Trans } from "react-i18next";

import styles from "./Result.module.scss";
import SustainablilityLevel from "../SustainabilityLevel/SustainabilityLevel";
import { LevelOfGovernance } from "../../../enums/LevelOfGovernance";

import roadmap from "../../../assets/roadmap.svg";
import activating from "../../../assets/roadmap_activating.svg";
import conform from "../../../assets/roadmap_conform.svg";
import proactive from "../../../assets/roadmap_proactive.svg";
import strategic from "../../../assets/roadmap_strategic.svg";
import IReadiness from "../../../interfaces/IReadiness";
import ShowError from "../../ui/ShowError";
import IQuestionnaire from "../../../interfaces/IQuestionnaire";

interface ResultProps extends WithTranslation {
  readiness: IReadiness;
  questionnaire: IQuestionnaire;
}

function Result({
  readiness,
  questionnaire,
  t,
}: ResultProps): FunctionComponentElement<ResultProps> {
  function getImage() {
    if (readiness.levelOfGovernance) {
      const level = readiness.levelOfGovernance;
      switch (level) {
        case LevelOfGovernance.ACTIVATING:
          return activating;
        case LevelOfGovernance.CONFORM:
          return conform;
        case LevelOfGovernance.PROACTIVE:
          return proactive;
        case LevelOfGovernance.STRATEGIC:
          return strategic;
        default:
          return roadmap;
      }
    }
    return roadmap;
  }

  if (!readiness.levelOfGovernance) {
    return <ShowError message={t("common:contact-us-error-msg") || ""} />;
  }

  return (
    <div className="my-3">
      <h2>{t("heading")}</h2>
      <h6>
        <i>{t("sub-heading")}</i>
      </h6>

      <div className="mt-3 d-flex justify-content-start">
        <span>
          <h6>
            <b>1.</b>
          </h6>
        </span>
        <div>
          <h6 className="mb-0">
            <b>{t("reg-requirements")}</b>
          </h6>
          <p className="mb-0">{`${t("req-csrd")}: ${t(
            readiness.sustainabilityReporing_CSRD
          )}`}</p>
          <p className="mb-0">{`${t("req-eu-taxonomy")}: ${t(
            readiness.reporting_EU_Taxonomie
          )}`}</p>
          <p>{`${t("req-lksg")}: ${t(readiness.reporting_LKSG)}`}</p>
        </div>
      </div>

      <div className="mt-3 d-flex justify-content-start">
        <span>
          <h6>
            <b>2.</b>
          </h6>
        </span>
        <div>
          <h6 className="mb-0">
            <b>{t("esg-level")}</b>
          </h6>
          <p>
            <Trans
              i18nKey={t("esg-level-desc")}
              values={{
                level: t(
                  readiness.levelOfGovernance?.toLowerCase()
                ).toLowerCase(),
              }}
              components={{ b: <b /> }}
            />
          </p>
        </div>
      </div>

      <div className={styles.imgContainer}>
        <img
          id="roadmap"
          src={getImage()}
          alt="roadmap"
          className={styles.image}
        />
      </div>

      {readiness.levelOfGovernance && (
        <p>
          <Trans
            i18nKey={t(
              `txt-${Object.values(LevelOfGovernance)
                .find((level) => level === readiness.levelOfGovernance)
                ?.toLowerCase()}`
            )}
            components={{ b: <b />, u: <u /> }}
          />
        </p>
      )}

      <p>
        {" "}
        <Trans i18nKey={t("txt-detail")} components={{ b: <b />, u: <u /> }} />
      </p>

      <SustainablilityLevel
        readiness={readiness}
        questionnaire={questionnaire}
      />

      <div className={styles.servicesSection}>
        <div className={styles.servicesHeader}>
          <h6 className="mb-0">
            <b>{t("services-section")}</b>
          </h6>
        </div>
        <div className={styles.servicesContent}>
          <p className="mb-0">
            <Trans
              i18nKey={t("services-desc")}
              components={{
                linkWebsite: (
                  <a
                    href="https://www.bdo.de/de-de/services/audit-assurance/sustainability-services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    link
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
export default withTranslation(["result", "common"])(Result);
