import { FunctionComponentElement, useState } from "react";
import cn from "classnames";
import { withTranslation, WithTranslation } from "react-i18next";

import {
  LevelOfGovernance,
  LevelOfGovernanceScore,
} from "../../../enums/LevelOfGovernance";
import styles from "./SustainabilityLevel.module.scss";
import IReadiness from "../../../interfaces/IReadiness";
import IAssessmentAnswer from "../../../interfaces/IAssessmentAnswer";
import IQuestionnaire from "../../../interfaces/IQuestionnaire";
import ShowError from "../../ui/ShowError";

const levelColors = [
  "dark-turquoise",
  "light-turquoise",
  "dark-gray",
  "light-gray",
];

interface SustainabililityLevelProps extends WithTranslation {
  readiness: IReadiness;
  questionnaire: IQuestionnaire;
}

function SustainabililityLevel({
  readiness,
  questionnaire,
  t,
}: SustainabililityLevelProps): FunctionComponentElement<SustainabililityLevelProps> {
  const [hasError, setHasError] = useState(false);

  const { assessmentDTOs } = readiness;
  const assessmentAnswers: IAssessmentAnswer[] = assessmentDTOs.map((item) => {
    const questionName = questionnaire.questions.find(
      (q) => q.id === item.questionId
    )?.questionName;
    if (!questionName) {
      setHasError(true);
      return {
        questionName: "",
        questionId: item.questionId,
        answer: item.answer,
        additionalInformation: item.additionalInformation,
      };
    }
    return {
      questionName: questionName,
      questionId: item.questionId,
      answer: item.answer,
      additionalInformation: item.additionalInformation,
    };
  });

  function groupAssessmentByLevel(): { [key: string]: IAssessmentAnswer[] } {
    const resultWithLevelKeys: { [key: string]: IAssessmentAnswer[] } =
      Object.keys(LevelOfGovernance).reduce(
        (
          result: { [key: string]: IAssessmentAnswer[] },
          level: string
        ): { [key: string]: IAssessmentAnswer[] } => {
          return {
            ...result,
            [level]: [],
          };
        },
        {}
      );
    return assessmentAnswers.reduce(
      (
        previous: { [key: string]: IAssessmentAnswer[] },
        current: IAssessmentAnswer
      ): { [key: string]: IAssessmentAnswer[] } => {
        if (!current.answer || current.answer.score === 0) return previous;

        const assessments = [
          ...(previous[LevelOfGovernanceScore[current.answer.score]] || []),
          current,
        ];

        return {
          ...previous,
          [LevelOfGovernanceScore[current.answer.score]]: assessments,
        };
      },
      resultWithLevelKeys
    );
  }

  function getLevelColor(level: string): string {
    const indexOfLevel = Object.keys(LevelOfGovernance).indexOf(level);
    return levelColors[indexOfLevel % levelColors.length];
  }

  function getLevelValue(level: string): string {
    const indexOfLevel = Object.keys(LevelOfGovernance).indexOf(level);
    const valueofLevel = Object.values(LevelOfGovernance)[indexOfLevel];
    return valueofLevel;
  }

  if (hasError) {
    return <ShowError message={t("common:contact-us-error-msg") || ""} />;
  }

  return (
    <>
      {Object.entries(groupAssessmentByLevel()).map(([level, assessments]) => (
        <div key={level}>
          <div className={cn(styles.levelHeader, styles[getLevelColor(level)])}>
            {getLevelValue(level) && (
              <h6 className="mb-0">
                <b>{t(getLevelValue(level).toLowerCase())}</b>
              </h6>
            )}
          </div>
          {assessments.map((assessment) => (
            <p key={assessment.questionName} className="mb-0">
              {assessment.questionName}: {assessment.answer?.resultText}
            </p>
          ))}
        </div>
      ))}
    </>
  );
}
export default withTranslation("result")(SustainabililityLevel);
