import { FunctionComponentElement } from "react";
import { Spinner } from "react-bootstrap";

import styles from "./Loader.module.scss";

function Loader(): FunctionComponentElement<unknown> {
  return (
    <div className="loading-indicator">
      <Spinner className={styles.loader} animation="border" />
    </div>
  );
}

export default Loader;
