import React, { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import esgHeader from "../../assets/esg_header.webp";

function ShowError({
  t,
}: WithTranslation): FunctionComponentElement<WithTranslation> {
  return (
    <div>
      <img src={esgHeader} className="img-fluid" alt="esgHeader" />
      <div className="m-5">
        <h1>{t("title")}</h1>
        <h4>{t("description")} ¯\_(ツ)_/¯</h4>
        <Link to="/">
          <h6 className="mt-3">{t("homepageLink")}</h6>
        </Link>
      </div>
    </div>
  );
}

export default withTranslation("not-found")(ShowError);
