import { ChangeEvent, FunctionComponentElement, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AnswerInputType from "../../../enums/AnswerInputType";
import IAnswer from "../../../interfaces/IAnswer";
import IQuestion from "../../../interfaces/IQuestion";
import tooltip from "../../../assets/tooltip.png";
import styles from "./QuestionItem.module.scss";

interface QuestionItemProps extends WithTranslation {
  question: IQuestion;
  additionalInfo: string;
  selectedValue?: number;
  textAreaValue?: string;
  onSelectChange: (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    questionId: number
  ) => void;
  onTextAreaBlur: (questionId: number, text: string) => void;
}

function QuestionItem({
  question,
  additionalInfo,
  selectedValue,
  textAreaValue,
  onSelectChange,
  onTextAreaBlur,
  t,
}: QuestionItemProps): FunctionComponentElement<QuestionItemProps> {
  const [text, setText] = useState<string>(textAreaValue || "");

  function isTextBoxAnswer(answer: IAnswer): boolean {
    return answer.answerText.trim() === "Sonstige: Freitext";
  }

  const hasTextBox = question.answers.some((answer) => isTextBoxAnswer(answer));

  function handleTextAreaChange(event: React.ChangeEvent<any>) {
    setText(event.currentTarget.value);
  }

  function handleBlur() {
    onTextAreaBlur(question.id, text);
  }

  return (
    <div key={question.id} className="mx-1">
      <div className="mb-1">
        {question.text}
        {additionalInfo && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">{additionalInfo}</Tooltip>}
          >
            <img
              className={styles.tooltipIcon}
              src={tooltip}
              alt="additionalInfo"
            />
          </OverlayTrigger>
        )}
      </div>
      {question.answerInputType === AnswerInputType.Select && (
        <Form.Select
          className="mb-3"
          onChange={(e) => onSelectChange(e, question.id)}
          value={selectedValue}
        >
          <option key="blankChoice" hidden value="">
            {t("select-placeholder")}
          </option>
          {question.answers
            .filter((answer) => !isTextBoxAnswer(answer))
            .map((answer: IAnswer, index: number) => (
              <option key={answer.id} value={answer.id}>
                {answer.answerText}
              </option>
            ))}
        </Form.Select>
      )}
      {question.answerInputType === AnswerInputType.Radio && (
        <>
          {question.answers
            .filter((answer) => !isTextBoxAnswer(answer))
            .map((answer: IAnswer) => (
              <Form.Check
                name="flexRadioDefault"
                id={"index-" + answer.id}
                key={answer.id}
                type="radio"
                value={answer.id}
                checked={answer.id === selectedValue}
                label={answer.answerText}
                onChange={(e) => onSelectChange(e, question.id)}
              ></Form.Check>
            ))}
        </>
      )}
      {hasTextBox && (
        <Form.Group className="mt-4 mb-2" controlId="textarea">
          <Form.Control
            as="textarea"
            placeholder={t("textArea-placeholder") || ""}
            rows={3}
            value={text}
            onChange={(e) => handleTextAreaChange(e)}
            onBlur={handleBlur}
          />
        </Form.Group>
      )}
    </div>
  );
}

export default withTranslation("questionnaire")(QuestionItem);
