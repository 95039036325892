import { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

function PrivacyPolicy({
  t,
}: WithTranslation): FunctionComponentElement<WithTranslation> {
  return (
    <div className="my-3 d-flex flex-column flex-grow-1 justify-content-between">
      <div className="d-flex flex-column">
        <h3>{t("heading")}</h3>
        <hr />
        <h5 className="mt-3">{t("subHeading")}</h5>

        <b className="mt-3">{t("controller.heading")}</b>
        <span className="mt-1">{t("controller.description")}</span>
        <span className="mt-2">{t("controller.address1")}</span>
        <span>{t("controller.address2")}</span>
        <span>{t("controller.telephone")}</span>
        <span>{t("controller.fax")}</span>
        <span>{t("controller.website")}</span>

        <b className="mt-3">{t("officer.heading")}</b>
        <span className="mt-1">{t("officer.name")}</span>
        <span>{t("officer.company")}</span>
        <span>{t("officer.address1")}</span>
        <span>{t("officer.address2")}</span>
        <span>{t("officer.telephone")}</span>
        <span>{t("officer.email")}</span>

        <b className="mt-3">{t("generalInfo.heading")}</b>
        <span className="mt-1">{t("generalInfo.para1")}</span>
        <span className="mt-3">{t("generalInfo.para2")}</span>
        <span className="mt-3">{t("generalInfo.para3")}</span>
        <span className="mt-3">{t("generalInfo.para4")}</span>
        <span className="mt-3">{t("generalInfo.para5")}</span>

        <b className="mt-3">{t("scope.heading")}</b>
        <span className="mt-1">{t("scope.description")}</span>

        <b className="mt-3">{t("legal.heading")}</b>
        <b className="mt-3">{t("legal.section1.heading")}</b>
        <span className="mt-1">{t("legal.section1.para")}</span>
        <b className="mt-3">{t("legal.section2.heading")}</b>
        <span className="mt-1">{t("legal.section2.para1")}</span>
        <span className="mt-1">{t("legal.section2.para2")}</span>
        <b className="mt-3">{t("legal.section3.heading")}</b>
        <span className="mt-1">{t("legal.section3.para")}</span>
        <b className="mt-3">{t("legal.section4.heading")}</b>
        <span className="mt-1">{t("legal.section4.para")}</span>

        <b className="mt-3">{t("storage.heading")}</b>
        <span className="mt-1">{t("storage.para")}</span>

        <b className="mt-3">{t("security.heading")}</b>
        <span className="mt-1">{t("security.para1")}</span>
        <span className="mt-3">{t("security.para2")}</span>

        <b className="mt-3">{t("portal.heading")}</b>
        <span className="mt-1">{t("portal.description1")}</span>
        <span className="mt-3">{t("portal.description2")}</span>
        <div className="d-flex flex-column my-2 ms-4">
          <li>{t("portal.data1")}</li>
          <li>{t("portal.data2")}</li>
          <li>{t("portal.data3")}</li>
          <li>{t("portal.data4")}</li>
          <li>{t("portal.data5")}</li>
          <li>{t("portal.data6")}</li>
          <li>{t("portal.data7")}</li>
          <li>{t("portal.data8")}</li>
        </div>
        <span>{t("portal.description3")}</span>
        <span className="mt-3">{t("portal.para1")}</span>
        <span className="mt-3">{t("portal.para2")}</span>
        <span className="mt-3">{t("portal.para3")}</span>
        <span className="mt-3">{t("portal.para4")}</span>
        <span className="mt-3">{t("portal.para5")}</span>

        <b className="mt-3">{t("dataEntry.heading")}</b>
        <span className="mt-1">{t("dataEntry.description")}</span>
        <div className="d-flex flex-column my-2 ms-4">
          <li>{t("dataEntry.item1")}</li>
          <li>{t("dataEntry.item2")}</li>
          <li>{t("dataEntry.item3")}</li>
          <li>{t("dataEntry.item4")}</li>
          <li>{t("dataEntry.item5")}</li>
        </div>
        <b className="mt-3">{t("dataEntry.section1.heading")}</b>
        <span className="mt-1">{t("dataEntry.section1.para")}</span>
        <b className="mt-3">{t("dataEntry.section2.heading")}</b>
        <span className="mt-1">{t("dataEntry.section2.para1")}</span>
        <span className="mt-3">{t("dataEntry.section2.para2")}</span>
        <span className="mt-3">{t("dataEntry.section2.para3")}</span>
        <span className="mt-3">{t("dataEntry.section2.para4")}</span>
        <b className="mt-3">{t("dataEntry.section3.heading")}</b>
        <span className="mt-1">{t("dataEntry.section3.para1")}</span>
        <span className="mt-3">{t("dataEntry.section3.para2")}</span>
        <div className="d-flex flex-column my-2 ms-4">
          <li>{t("dataEntry.section3.item1")}</li>
          <li>{t("dataEntry.section3.item2")}</li>
          <li>{t("dataEntry.section3.item3")}</li>
        </div>
        <span className="mt-1">{t("dataEntry.section3.para3")}</span>
        <span className="mt-3">{t("dataEntry.section3.para4")}</span>
        <b className="mt-3">{t("dataEntry.section4.heading")}</b>
        <span className="mt-1">{t("dataEntry.section4.para1")}</span>
        <span className="mt-3">{t("dataEntry.section4.para2")}</span>
        <span className="mt-3">{t("dataEntry.section4.para3")}</span>

        <b className="mt-3">{t("recipients.heading")}</b>
        <span className="mt-1">{t("recipients.para1")}</span>
        <span className="mt-3">{t("recipients.para2")}</span>
        <span className="mt-3">{t("recipients.para3")}</span>

        <b className="mt-3">{t("rights.heading")}</b>
        <span className="mt-1">{t("rights.description")}</span>

        <b className="mt-3">{t("rights.right1.heading")}</b>
        <span className="mt-1">{t("rights.right1.para1")}</span>
        <ol className="d-flex flex-column my-2 ms-4">
          <li>{t("rights.right1.item1")}</li>
          <li>{t("rights.right1.item2")}</li>
          <li>{t("rights.right1.item3")}</li>
          <li>{t("rights.right1.item4")}</li>
          <li>{t("rights.right1.item5")}</li>
          <li>{t("rights.right1.item6")}</li>
          <li>{t("rights.right1.item7")}</li>
          <li>{t("rights.right1.item8")}</li>
        </ol>
        <span>{t("rights.right1.para2")}</span>

        <b className="mt-3">{t("rights.right2.heading")}</b>
        <span className="mt-1">{t("rights.right2.para")}</span>

        <b className="mt-3">{t("rights.right3.heading")}</b>
        <span className="mt-1">{t("rights.right3.para1")}</span>
        <ol className="d-flex flex-column my-2 ms-4">
          <li>{t("rights.right3.item1")}</li>
          <li>{t("rights.right3.item2")}</li>
          <li>{t("rights.right3.item3")}</li>
          <li>{t("rights.right3.item4")}</li>
        </ol>
        <span>{t("rights.right3.para2")}</span>

        <b className="mt-3">{t("rights.right4.heading")}</b>
        <b className="mt-3">{t("rights.right4.section1.heading")}</b>
        <span className="mt-1">{t("rights.right4.section1.para")}</span>
        <ol className="d-flex flex-column my-2 ms-4">
          <li>{t("rights.right4.section1.item1")}</li>
          <li>{t("rights.right4.section1.item2")}</li>
          <li>{t("rights.right4.section1.item3")}</li>
          <li>{t("rights.right4.section1.item4")}</li>
          <li>{t("rights.right4.section1.item5")}</li>
          <li>{t("rights.right4.section1.item6")}</li>
          <li>{t("rights.right4.section1.item7")}</li>
        </ol>
        <b className="mt-3">{t("rights.right4.section2.heading")}</b>
        <span className="mt-1">{t("rights.right4.section2.para")}</span>
        <b className="mt-3">{t("rights.right4.section3.heading")}</b>
        <span className="mt-1">{t("rights.right4.section3.para")}</span>
        <ol className="d-flex flex-column my-2 ms-4">
          <li>{t("rights.right4.section3.item1")}</li>
          <li>{t("rights.right4.section3.item2")}</li>
          <li>{t("rights.right4.section3.item3")}</li>
          <li>{t("rights.right4.section3.item4")}</li>
          <li>{t("rights.right4.section3.item5")}</li>
        </ol>

        <b className="mt-3">{t("rights.right5.heading")}</b>
        <span className="mt-1">{t("rights.right5.para")}</span>

        <b className="mt-3">{t("rights.right6.heading")}</b>
        <span className="mt-1">{t("rights.right6.para1")}</span>
        <ol className="d-flex flex-column my-2 ms-4">
          <li>{t("rights.right6.item1")}</li>
          <li>{t("rights.right6.item2")}</li>
        </ol>
        <span>{t("rights.right6.para2")}</span>
        <span className="mt-3">{t("rights.right6.para3")}</span>

        <b className="mt-3">{t("rights.right7.heading")}</b>
        <span className="mt-1">{t("rights.right7.para1")}</span>
        <span className="mt-3">{t("rights.right7.para2")}</span>
        <span className="mt-3">{t("rights.right7.para3")}</span>

        <b className="mt-3">{t("rights.right8.heading")}</b>
        <span className="mt-1">{t("rights.right8.para")}</span>

        <b className="mt-3">{t("rights.right9.heading")}</b>
        <span className="mt-1">{t("rights.right9.para1")}</span>
        <ol className="d-flex flex-column my-2 ms-4">
          <li>{t("rights.right9.item1")}</li>
          <li>{t("rights.right9.item2")}</li>
          <li>{t("rights.right9.item3")}</li>
        </ol>
        <span>{t("rights.right9.para2")}</span>
        <span className="mt-3">{t("rights.right9.para3")}</span>

        <b className="mt-3">{t("rights.right10.heading")}</b>
        <span className="mt-1">{t("rights.right10.para1")}</span>
        <span className="mt-3">{t("rights.right10.para2")}</span>

        <span className="mt-4">{t("status")}</span>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button key="close" onClick={() => window.close()}>
          {t("btn-close", { ns: "common" })}
        </Button>
      </div>
    </div>
  );
}
export default withTranslation(["privacy-policy", "common"])(PrivacyPolicy);
