import { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Imprint({
  t,
}: WithTranslation): FunctionComponentElement<WithTranslation> {
  return (
    <div className="my-3 d-flex flex-column flex-grow-1 justify-content-between">
      <div className="d-flex flex-column">
        <h3>{t("heading")}</h3>
        <hr />
        <h5 className="mt-3">{t("heading")}</h5>
        <span className="mt-2">{t("company.name")}</span>
        <span>{t("company.address1")}</span>
        <span>{t("company.address2")}</span>
        <span>{t("company.telephone")}</span>
        <span>{t("company.fax")}</span>
        <span className="mt-3">{t("company.email")}</span>
        <b className="mt-3">{t("directors.heading")}:</b>
        <span>{t("directors.content")}</span>
        <b className="mt-3">{t("headquarters.heading")}:</b>
        <span>{t("headquarters.content")}</span>
        <b className="mt-3">{t("vatId.heading")}:</b>
        <span>{t("vatId.content")}</span>
        <b className="mt-3">{t("regulatoryBody.heading")}:</b>
        <span>{t("regulatoryBody.content1")}</span>
        <span className="mt-2">{t("regulatoryBody.content2")}</span>
        <span className="mt-2">{t("regulatoryBody.content3")}</span>
        <b className="mt-3">{t("locations.heading")}</b>
        <span>{`${t("locations.hamburg.name")}: ${t(
          "locations.hamburg.address"
        )}`}</span>
        <span>{t("locations.hamburg.contact")}</span>
        <span className="mt-2">{`${t("locations.stuttgart.name")}: ${t(
          "locations.stuttgart.address"
        )}`}</span>
        <span>{t("locations.stuttgart.contact")}</span>
        <span className="mt-2">{`${t("locations.munich.name")}: ${t(
          "locations.munich.address"
        )}`}</span>
        <span>{t("locations.munich.contact")}</span>
        <span className="mt-2">{`${t("locations.berlin.name")}: ${t(
          "locations.berlin.address"
        )}`}</span>
        <span>{t("locations.berlin.contact")}</span>
        <span className="mt-2">{`${t("locations.frankfurt.name")}: ${t(
          "locations.frankfurt.address"
        )}`}</span>
        <span>{t("locations.frankfurt.contact")}</span>
        <span className="mt-2">{`${t("locations.kassel.name")}: ${t(
          "locations.kassel.address"
        )}`}</span>
        <span>{t("locations.kassel.contact")}</span>
        <span className="mt-2">{`${t("locations.oldenburg.name")}: ${t(
          "locations.oldenburg.address"
        )}`}</span>
        <span>{t("locations.oldenburg.contact")}</span>
        <span className="mt-2">{`${t("locations.dusseldorf.name")}: ${t(
          "locations.dusseldorf.address"
        )}`}</span>
        <span>{t("locations.dusseldorf.contact")}</span>
        <span className="mt-2">{`${t("locations.cologne.name")}: ${t(
          "locations.cologne.address"
        )}`}</span>
        <span>{t("locations.cologne.contact")}</span>
        <span className="mt-2">{`${t("locations.muenster.name")}: ${t(
          "locations.muenster.address"
        )}`}</span>
        <span>{t("locations.muenster.contact")}</span>
        <b className="mt-3">{t("rules.heading")}:</b>
        <span>{t("rules.description")}</span>
        <div className="d-flex flex-column mt-2 ms-3">
          <span>{t("rules.rule1")}</span>
          <span>{t("rules.rule2")}</span>
          <span>{t("rules.rule3")}</span>
          <span>{t("rules.rule4")}</span>
          <span>{t("rules.rule5")}</span>
          <span>{t("rules.rule6")}</span>
        </div>
        <span className="mt-2">{t("rules.link")}</span>
        <b className="mt-3">{t("insurance.heading")}:</b>
        <span>{t("insurance.content")}</span>
        <b className="mt-3">{t("dispute.heading")}:</b>
        <span>{t("dispute.content")}</span>
        <span>
          <Trans
            i18nKey={t("dispute.link")}
            components={{
              linkDispute: (
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
                  link
                </a>
              ),
            }}
          />
        </span>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button key="close" onClick={() => window.close()}>
          {t("btn-close", { ns: "common" })}
        </Button>
      </div>
    </div>
  );
}
export default withTranslation(["imprint", "common"])(Imprint);
