import { FunctionComponentElement } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import roadmap from "../../../assets/roadmap.svg";
import styles from "./Intro.module.scss";

function Intro({
  t,
}: WithTranslation): FunctionComponentElement<WithTranslation> {
  return (
    <div className="mt-3">
      <h2 className="mb-4">{t("heading")}</h2>
      <div className="d-flex-column mb-4">
        <h6 className="mb-1">
          <b>{t("section1-heading")}</b>
        </h6>
        <p>{t("section1-para")}</p>
      </div>
      <div className="d-flex-column mb-4">
        <h6 className="mb-1">
          <b>{t("section2-heading")}</b>
        </h6>
        <p>{t("section2-para")}</p>
      </div>
      <h5 className="mb-0">
        <b>{t("description")}</b>
      </h5>
      <div className={styles.imgContainer}>
        <img src={roadmap} alt="roadmap" className={styles.image} />
      </div>
    </div>
  );
}

export default withTranslation("intro")(Intro);
