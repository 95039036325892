import axios, { AxiosResponse } from "axios";
import i18next from "i18next";
import { config } from "../configs/app.settings";
import IQuestionnaire from "../interfaces/IQuestionnaire";

class QuestionnaireService {
  lang = i18next.language;
  fetchPath = "Questionnaire";

  public getQuestionnaire(): Promise<AxiosResponse<IQuestionnaire>> {
    const identifier = 1;
    const response = axios<IQuestionnaire>({
      method: "get",
      url: `${config.url.API_URL}${this.fetchPath}?lang=${this.lang}&id=${identifier}`,
    });
    return response;
  }
}
export default QuestionnaireService;
