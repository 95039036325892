import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
}



class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: new Error()
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
     return { hasError: true, error: _ };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//    console.error("Error:", error, errorInfo);
  }

  public render() {
     if (this.state.hasError) {
      return(
        <>
       <h1>An error has occured.</h1>  
       {this.state.error.name}
       {this.state.error.message}
       {this.state.error.stack}

        

       </>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;