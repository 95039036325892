import axios from "axios";
import i18next from "i18next";
import { config } from "../configs/app.settings";
import IAssessment from "../interfaces/IAssessment";
import IReadiness from "../interfaces/IReadiness";
import IAssessmentPdf from "../interfaces/IAssessmentPdf";

class AssessmentService {
  lang = i18next.language;
  fetchPath = "Assessment";

  getReadiness(questionnaireResult: IAssessment[]) {
    let assessmentResult: IAssessment[] = [];

    questionnaireResult.forEach((result) => {
      assessmentResult.push({
        questionId: result.questionId,
        answer: result.answer,
        additionalInformation: result.additionalInformation || "",
      });
    });
    return axios<IReadiness>({
      method: "post",
      url: `${config.url.API_URL}${this.fetchPath}?lang=${this.lang}`,
      data: assessmentResult,
    });
  }

  sendAssessmentPdf(assessmentPdf: IAssessmentPdf) {
    return axios<any>({
      method: "post",
      url: `${config.url.API_URL}${this.fetchPath}/Pdf`,
      data: assessmentPdf,
    });
  }
}
export default AssessmentService;
