import axios, { AxiosResponse } from "axios";
import { config } from "../configs/app.settings";
import IContactForm from "../interfaces/IContactForm";
import IdEmail from "../interfaces/IParticipantId";


class ContactFormService {
  fetchPath = "ContactForm";
  sendOptInPath = 'OptIn';

  public addContact(
    contactFormInfo: IContactForm
  ): Promise<AxiosResponse<number>> {
    return axios<number>({
      method: "post",
      url: config.url.API_URL + this.fetchPath,
      data: contactFormInfo,
    });
  }

  
  public sendOptIn(
    contactFormInfo: IContactForm
  ): Promise<AxiosResponse> {
    return axios({
      method: "post",
      url: config.url.API_URL + this.sendOptInPath,
      data: contactFormInfo,
    });
  }
}


export default ContactFormService;
