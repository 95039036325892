import {
  FunctionComponentElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import Carousel from "../Carousel/Carousel";
import IAssessment from "../../../interfaces/IAssessment";
import IQuestionnaire from "../../../interfaces/IQuestionnaire";
import QuestionItem from "../QuestionItem/QuestionItem";

interface QuestionnaireProps extends WithTranslation {
  questionnaire: IQuestionnaire;
  onLastQuestionNextClick: (assessment: IAssessment[]) => void;
}

function Questionnaire({
  questionnaire,
  onLastQuestionNextClick,
  t,
}: QuestionnaireProps): FunctionComponentElement<QuestionnaireProps> {
  const [questionGroupIndex, setQuestionGroupIndex] = useState(0);
  const [assessment, setAssessment] = useState<IAssessment[]>([]);
  const numberOfQuestionGroups = questionnaire.questionGroups.length;

  const checkIfAllQuestionsAreAnswered = useCallback(() => {
    const currentQuestionIds = questionnaire.questionGroups[
      questionGroupIndex
    ].questions.map((question) => question.id);

    const answered = assessment.filter(
      (ass) =>
        !!currentQuestionIds.find((questionId) => questionId === ass.questionId)
    );

    if (answered.length < currentQuestionIds.length) return false;

    return answered.every((item) => item.answer !== undefined);
  }, [assessment, questionGroupIndex, questionnaire.questionGroups]);

  const [isQuestionGroupAnswered, setIsQuestionGroupAnswered] =
    useState<boolean>(checkIfAllQuestionsAreAnswered());

  function next(): void {
    // Completed
    if (questionGroupIndex + 1 === numberOfQuestionGroups) {
      onLastQuestionNextClick(assessment);
    } else {
      setQuestionGroupIndex((index) => index + 1);
    }
  }

  function back(): void {
    setQuestionGroupIndex((index) => (index - 1 > 0 ? index - 1 : 0));
  }

  function handleSelectChange(
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    questionId: number
  ) {
    let updated: IAssessment[] = [];

    const assessmentExists = assessment.some(
      (ass) => ass.questionId === questionId
    );
    if (assessmentExists) {
      updated = assessment.map((ass) => {
        if (ass.questionId === questionId) {
          const answer = questionnaire.questions
            .find((q) => q.id === questionId)
            ?.answers.find((a) => a.id === parseInt(event.currentTarget.value));
          return { ...ass, answer: answer };
        }
        return ass;
      });
    } else {
      updated = [
        ...assessment,
        {
          questionId: questionId,
          answer: questionnaire.questions
            .find((q) => q.id === questionId)
            ?.answers.find((a) => a.id === parseInt(event.currentTarget.value)),
        },
      ];
    }
    setAssessment(updated);
  }

  function handleTextAreaBlur(questionId: number, text: string) {
    let updated: IAssessment[] = [];

    const assessmentExists = assessment.some(
      (ass) => ass.questionId === questionId
    );
    if (assessmentExists) {
      updated = assessment.map((ass) => {
        if (ass.questionId === questionId) {
          return { ...ass, additionalInformation: text };
        }
        return ass;
      });
    } else {
      updated = [
        ...assessment,
        {
          questionId: questionId,
          additionalInformation: text,
        },
      ];
    }
    setAssessment(updated);
  }

  function getSelectedValue(questionId: number): number | undefined {
    const selectedValue = assessment.find(
      (ass) => ass.questionId === questionId
    );
    return selectedValue?.answer?.id;
  }

  function getTextAreaValue(questionId: number): string {
    const textAreaValue = assessment.find(
      (ass) => ass.questionId === questionId
    );
    return textAreaValue?.additionalInformation || "";
  }

  function getQuestionAdditionalInfo(questionId: number): string {
    const question = questionnaire.questions.find((q) => q.id === questionId);
    return question?.additionalInformation || "";
  }

  useEffect(() => {
    const isAnswered = checkIfAllQuestionsAreAnswered();
    setIsQuestionGroupAnswered(isAnswered);
  }, [checkIfAllQuestionsAreAnswered]);

  return (
    <Carousel
      activeQuestionGroupIndex={questionGroupIndex}
      isQuestionGroupAnswered={isQuestionGroupAnswered}
      onNextClick={next}
      onPreviousClick={back}
      header={
        <div className="d-flex align-items-baseline">
          <h3>
            {t("question")} {questionGroupIndex + 1}
            {"/"}
          </h3>
          <h5>{numberOfQuestionGroups}</h5>
        </div>
      }
    >
      <div className="questionnaire-content">
        {questionnaire.questionGroups[questionGroupIndex].questions.map(
          (question) => (
            <QuestionItem
              key={question.id}
              question={question}
              selectedValue={getSelectedValue(question.id)}
              textAreaValue={getTextAreaValue(question.id)}
              onSelectChange={handleSelectChange}
              onTextAreaBlur={handleTextAreaBlur}
              additionalInfo={getQuestionAdditionalInfo(question.id)}
            />
          )
        )}
      </div>
    </Carousel>
  );
}

export default withTranslation("questionnaire")(Questionnaire);
