const prod = {
  url: {
    API_URL: " https://bdo-esg-readiness-prod-backend.azurewebsites.net/api/",
  },
};
const dev = {
  url: {
    API_URL: "https://localhost:7234/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
