import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import IntroPage from "./pages/IntroPage/IntroPage";
import ResultPage from "./pages/ResultPage";
import ImprintPage from "./pages/ImprintPage";
import ContactFormPage from "./pages/ContactFormPage";
import OutroPage from "./pages/OutroPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import NotFound from "./components/ui/NotFound";
import IReadiness from "./interfaces/IReadiness";
import IQuestionnaire from "./interfaces/IQuestionnaire";

type State = {
  readiness: IReadiness;
  questionnaire: IQuestionnaire;
};

class App extends React.Component<{}, State> {
  public readonly state: State = {
    readiness: {
      assessmentId: 0,
      assessmentDTOs: [],
      levelOfGovernance: "",
      sustainabilityReporing_CSRD: "",
      reporting_EU_Taxonomie: "",
      reporting_LKSG: "",
    },
    questionnaire: {
      id: 0,
      name: "",
      questions: [],
      questionGroups: [],
    },
  };

  setReadiness = (readiness: IReadiness) => {
    this.setState({ readiness: readiness });
  };

  setQuestionnaire = (questionnaire: IQuestionnaire) => {
    this.setState({ questionnaire: questionnaire });
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <IntroPage
                setReadiness={this.setReadiness}
                setAppQuestionnaire={this.setQuestionnaire}
              />
            }
          />
          <Route
            path="result"
            element={
              <ResultPage
                readiness={this.state.readiness}
                questionnaire={this.state.questionnaire}
              />
            }
          />
          <Route
            path="contact-form/:assessmentId"
            element={<ContactFormPage />}
          />
          <Route
            path="contact-form-pdf/:assessmentId"
            element={<ContactFormPage />}
          />
          <Route
            path="contact-form/:assessmentId/complete"
            element={<OutroPage />}
          />
          <Route
            path="contact-form-pdf/:assessmentId/complete"
            element={<OutroPage isPdfRedirected={true} />}
          />
          <Route path="imprint" element={<ImprintPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
