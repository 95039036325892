import { FunctionComponentElement, useState } from "react";
import cn from "classnames";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";

import IContactForm from "../interfaces/IContactForm";
import ContactFormService from "../api/ContactFormService";
import ContactForm from "../components/form/ContactForm/ContactForm";
import Loader from "../components/ui/Loader/Loader";
import ShowError from "../components/ui/ShowError";

interface ContactFormPageProps extends WithTranslation {}

function ContactFormPage({
  t,
}: ContactFormPageProps): FunctionComponentElement<ContactFormPageProps> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams<{ assessmentId: string }>();

  function handleCheckboxChange() {
    setIsChecked((prev) => !prev);
  }

  function handleNextClick(formDetails: IContactForm): void {
    setLoading(true);
    formDetails.assessmentId = params.assessmentId || "";

    var contractFormPromise = new ContactFormService().addContact(formDetails);

    var optInPromise = new ContactFormService().sendOptIn(formDetails);

    Promise.all([contractFormPromise, optInPromise])
      .then(() => {
        navigate("complete");
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (error) {
    return <ShowError error={error} />;
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container-background">
        <div className="container">
          <div className="fake-header-button" />
          <div
            className={cn(
              "frame",
              "d-flex flex-column",
              "border border-1 rounded-3",
              "fullScreenPortrait"
            )}
          >
            <ContactForm
              isAgreementChecked={isChecked}
              onNextClick={handleNextClick}
            />
          </div>
          <Form.Group className="mt-3" controlId="checkbox">
            <Form.Check
              type="checkbox"
              label={
                <Trans
                  i18nKey={t("agreement")}
                  components={{
                    linkPrivacyPolicy: (
                      <Link to="/privacy-policy" target="_blank" />
                    ),
                  }}
                />
              }
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
}

export default withTranslation("contact-form")(ContactFormPage);
