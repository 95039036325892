import { FunctionComponentElement, useState, useEffect } from "react";
import cn from "classnames";
import { Button } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";

import IQuestionnaire from "../../interfaces/IQuestionnaire";
import Assessment from "../../components/assessment/Assessment/Assessment";
import Intro from "../../components/static/Intro/Intro";
import Loader from "../../components/ui/Loader/Loader";

import styles from "./IntroPage.module.scss";
import QuestionnaireService from "../../api/QuestionnaireService";
import ShowError from "../../components/ui/ShowError";
import IReadiness from "../../interfaces/IReadiness";

interface IntroPageProps extends WithTranslation {
  setReadiness: (readiness: IReadiness) => void;
  setAppQuestionnaire: (questionnaire: IQuestionnaire) => void;
}

function IntroPage({
  setReadiness,
  setAppQuestionnaire,
  t,
}: IntroPageProps): FunctionComponentElement<IntroPageProps> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [questionnaire, setQuestionnaire] = useState<
    IQuestionnaire | undefined
  >(undefined);
  const [hasAssessmentStarted, setHasAssessmentStarted] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      new QuestionnaireService()
        .getQuestionnaire()
        .then((response) => {
          setQuestionnaire(response.data);
          setAppQuestionnaire(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  if (error) {
    return <ShowError error={error} />;
  }
  if (loading || !questionnaire) {
    return <Loader />;
  }

  return (
    <div className="container-background">
      <div className="container">
        <div className="header">
          <Button
            onClick={() => setHasAssessmentStarted(true)}
            className={cn("header-button", {
              [styles.hide]: hasAssessmentStarted,
            })}
          >
            {t("btn-start-assessment")}
          </Button>
        </div>

        {hasAssessmentStarted ? (
          <div
            className={cn(
              "frame",
              "d-flex flex-column",
              "border border-1 rounded-3",
              "fullScreenPortrait"
            )}
          >
            <Assessment
              questionnaire={questionnaire}
              setReadiness={setReadiness}
            />
          </div>
        ) : (
          <div className={cn("frame", "border border-1 rounded-3")}>
            <Intro />
          </div>
        )}

        {!hasAssessmentStarted && (
          <div className={cn("mt-2", "footer-links")}>
            <>
              <a href="/imprint" target="_blank">
                {t("link-imprint")}
              </a>
              <a href="/privacy-policy" target="_blank" className="ms-3">
                {t("link-data-protection")}
              </a>
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default withTranslation("intro")(IntroPage);
