import { FunctionComponentElement, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Button } from "react-bootstrap";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import html2pdf from "html2pdf.js";

import Result from "../components/result/Result/Result";
import IReadiness from "../interfaces/IReadiness";
import AssessmentService from "../api/AssessmentService";
import logo from "../assets/bdo_logo.png";
import IQuestionnaire from "../interfaces/IQuestionnaire";
interface ResultPageProps extends WithTranslation {
  readiness: IReadiness;
  questionnaire: IQuestionnaire;
}

function ResultPage({
  readiness,
  questionnaire,
  t,
}: ResultPageProps): FunctionComponentElement<ResultPageProps> {
  const printPdfRef = useRef(null);
  const generatePdfRef = useRef(null);

  const options = {
    margin: 0.75,
    filename: `ESG_Readiness_Assessment_${readiness.assessmentId}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, letterRendering: true },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    autoPaging: "text",
  };

  const handlePrint = useReactToPrint({
    content: () => printPdfRef.current,
    copyStyles: true,
  });

  const handleGeneratePdf = useReactToPrint({
    onPrintError: (error) => console.error(error),
    content: () => generatePdfRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = generatePdfRef.current;
        html2pdf()
          .set(options)
          .from(html)
          .toPdf()
          .get("pdf")
          .then((pdf: any) => {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(5);
              pdf.setTextColor(0);
              pdf.addImage(
                logo,
                "png",
                pdf.internal.pageSize.getWidth() - 1.6,
                pdf.internal.pageSize.getHeight() - 0.6,
                0.8,
                0.3
              );
            }
          })
          .output("datauristring")
          .then((pdfAsString: any) => {
            new AssessmentService().sendAssessmentPdf({
              assessmentId: readiness.assessmentId,
              fileUri: pdfAsString,
            });
          });
      }
    },
  });

  useEffect(() => {
    handleGeneratePdf();
  }, [handleGeneratePdf]);

  return (
    <>
      <div className="container-background">
        <div className="container">
          <div className="result-header">
            <Link
              to={`/contact-form/${readiness.assessmentId}`}
              target="_blank"
            >
              <Button className="header-button">{t("btn-contact-form")}</Button>
            </Link>
          </div>
          <div className={cn("frame", "border border-1 rounded-3")}>
            <Result readiness={readiness} questionnaire={questionnaire} />
          </div>
          <div className={cn("d-flex justify-content-end", "mt-4")}>
            <Button
              onClick={handlePrint}
              className={cn("header-button", "print-button")}
            >
              {t("btn-print-pdf")}
            </Button>
          </div>
        </div>
      </div>
      {/* This element is printed when print button is clicked */}
      <div className="page-break">
        <div ref={printPdfRef}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Result readiness={readiness} questionnaire={questionnaire} />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={cn("d-flex justify-content-center", "my-2")}>
                    <Link
                      to={`/contact-form-pdf/${readiness.assessmentId}`}
                      target="_blank"
                    >
                      <Button className="header-button">
                        {t("btn-contact-us-pdf")}
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tfoot>
          </table>

          <div className="page-footer">
            <img alt={"logo"} src={logo} className="logo" />
          </div>
        </div>
      </div>
      {/* This element is automatically printed and sent as email */}
      <div className="page-break">
        <div ref={generatePdfRef} className="page">
          <Result readiness={readiness} questionnaire={questionnaire} />
        </div>
      </div>
    </>
  );
}

export default withTranslation("result")(ResultPage);
