import React, { FunctionComponentElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import esgHeader from "../../assets/esg_header.webp";

interface ShowErrorProps extends WithTranslation {
  error?: Error;
  message?: string;
}

function ShowError({
  error,
  message,
  t,
}: ShowErrorProps): FunctionComponentElement<ShowErrorProps> {
  console.error(error);
  return (
    <div>
      <img src={esgHeader} className="img-fluid" alt="esgHeader" />
      <div className="m-5">
        <h1>{t("error-msg")}</h1>
        {error && error.message === "Network Error" && (
          <h5>{t("service-unavailable")}</h5>
        )}
        {message && <h5>{message}</h5>}
      </div>
    </div>
  );
}

export default withTranslation("common")(ShowError);
