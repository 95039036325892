import { FunctionComponentElement, ReactNode } from "react";
import { Button } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";

import styles from "./Carousel.module.scss";

interface CarouselProps extends WithTranslation {
  activeQuestionGroupIndex?: number;
  isQuestionGroupAnswered?: boolean;
  isQuestionnaireCompleted?: boolean;

  onNextClick?(): void;
  onPreviousClick?(): void;
  onResultClick?(): void;

  header?: ReactNode;
  children: React.ReactNode;
}

function Carousel({
  activeQuestionGroupIndex,
  isQuestionGroupAnswered = false,
  isQuestionnaireCompleted = false,
  header,
  onNextClick,
  onPreviousClick,
  onResultClick,
  children,
  t,
}: CarouselProps): FunctionComponentElement<CarouselProps> {
  const isOnFirstQuestion = activeQuestionGroupIndex === 0;

  return (
    <>
      <div className={styles.questionContent}>
        {header && (
          <>
            {header}
            <hr />
          </>
        )}
        {children}
      </div>
      <div className="d-flex justify-content-end">
        {isQuestionnaireCompleted ? (
          <Button
            key={`continue-${activeQuestionGroupIndex}`}
            onClick={onResultClick}
          >
            {t("btn-evaluate")}
          </Button>
        ) : (
          <>
            <Button
              onClick={onPreviousClick}
              className="me-4"
              hidden={isOnFirstQuestion}
            >
              {t("btn-back")}
            </Button>
            <Button
              key={`next-${activeQuestionGroupIndex}`}
              onClick={onNextClick}
              disabled={!isQuestionGroupAnswered}
            >
              {t("btn-next")}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default withTranslation("common")(Carousel);
